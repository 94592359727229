<template>
  <div :class="{
    playcard: true,
    clickable
  }">
    <span v-if="index !== undefined" style="font-size: 9em" v-bind:style="{marginTop: shadow ? '-0.5em': 0}"
          v-html="unicode"/>
    <span v-if="back" style="font-size: 9em" v-html="'&#x1f0a0;'"/>
  </div>
</template>

<script>
import {Cards} from "@/Game/core";

export default {
  name: "Card",
  props: {
    index: Number,
    shadow: Boolean,
    clickable: Boolean,
    back: Boolean
  },
  computed: {
    card() {
      return Cards[this.$props.index]
    },
    unicode() {
      let code = '&#x1F0'
      const typeToUni = {
        '♠': 'A', '♥': 'B',
        '♦': 'C', '♣': 'D'
      }
      const numToUni = {
        '10': 'A', 'J': 'B',
        'Q': 'D', 'K': 'E', 'A': '1'
      }
      code += typeToUni[this.card.type]
      if (this.card.num in numToUni) {
        code += numToUni[this.card.num]
      } else {
        code += this.card.num
      }
      return code + ';'
    }
  }
}
</script>

<style scoped>
.playcard {
  justify-content: center;
  font-size: 20px;
  margin: 8px;
  display: flex;
  width: 6em;
  height: 9em;
  transition: box-shadow 0.2s;
  flex-direction: column;
}

.clickable:hover {
  color: red;
}
</style>

<template>
  <div v-if="phase==='chooseOpen'">
    <div class="cardrow" v-if="player">
      <Card
          v-for="(card, index) in player.hand"
          :key="card"
          :index="card"
          :shadow="cardSelected[index]"
          @click="cardClick(index)"
          clickable/>
    </div>
    <div class="text">{{ active ? "Select your three Open cards" : "Waiting for other players" }}</div>
    <div class="text" style="color: red">{{ errMsg }}</div>
    <Button v-if="active" class="el" v-on:click="OKPressed">OK</Button>
  </div>
  <div class="view" v-else-if="phase==='play'">
    <div class="cardrow" v-if="oponent">
      <Card v-for="card in oponent.open" :key="card" v-bind:index="card"/>
    </div>
    <div class="cardrow">
      <span style="max-width: 90px">Open Cards<hr/>{{ openCards.length }}</span>
      <Card :index="cardOnTop"/>
      <Card back/>
      <span style="max-width: 90px"> Left in Deck<hr/>{{ leftInDeck }}</span>
    </div>
    <div class="cardrow">
      <Card v-for="(card, index) in player.hand" :key="card" :index="card" @click="putCard(index)" clickable/>
    </div>
    <span v-if="myTurn">Your turn</span>
    <span>{{ errMsg }}</span>
  </div>
</template>

<script>
import {createNewClient} from "@/Game/client";
import Card from "@/components/Card";
import Button from "@/components/Button";

export default {
  name: "Game",
  props: {
    playerID: String,
    matchID: String,
    auth: String
  },
  components: {
    Button,
    Card
  },
  data() {
    return {
      state: {},
      cardSelected: Array(6).fill(false),
      errMsg: ''
    }
  },
  created() {
    this.client = createNewClient(this.matchID, this.playerID, this.auth)
    this.unsubscribe = this.client.subscribe(state => {
      this.state = state
    })
  },
  computed: {
    player() {
      return this.state?.G.players[this.playerID]
    },
    active() {
      return this.playerID in (this.state?.ctx?.activePlayers || [])
    },
    phase() {
      return this.state?.ctx.phase
    },
    oponent() {
      return this.state?.G.players[(1 - this.playerID)]
    },
    myTurn() {
      return this.state.ctx.currentPlayer === this.playerID
    },
    openCards() {
      return this.state?.G.open
    },
    cardOnTop() {
      return this.openCards[this.openCards.length - 1]
    },
    leftInDeck() {
      return this.state?.G.leftInDeck
    }
  },
  unmounted() {
    this.unsubscribe()
    this.client.stop()
  },
  methods: {
    cardClick(index) {
      this.cardSelected[index] = !this.cardSelected[index]
    },
    OKPressed() {
      let selected = this.player.hand.filter((c, i) => this.cardSelected[i])
      this.cardSelected = [false, false, false]
      if (selected.length === 3) {
        this.client.moves.openCards(selected)
        this.errMsg = ''
      } else {
        this.errMsg = "Please select exactly three cards"
      }
    },
    putCard(index) {
      this.client.moves.putCards([this.player.hand[index]])
    }
  }
}
</script>

<style scoped>
.cardrow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text {
  margin: 1em 0 1em 0;
}

span {
  font-weight: bold;
}
</style>
